import { render, staticRenderFns } from "./SubStructureModal.vue?vue&type=template&id=c42f7768&scoped=true&"
import script from "./SubStructureModal.vue?vue&type=script&lang=js&"
export * from "./SubStructureModal.vue?vue&type=script&lang=js&"
import style0 from "./SubStructureModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c42f7768",
  null
  
)

export default component.exports