<template>
  <b-modal
    size="2xl"
    id="sub-structure"
    ref="sub-structure"
    modal-class="sub-structure-modal"
    body-class="merger-history-body"
    ok-only
  >
    <template slot="modal-header">
      <h5 class="modal-title">Holding Company Structure</h5>
      <i class="fa fa-download fa-2x float-right" @click="downloadReport"></i>
    </template>
    <div v-if="loading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </div>
    <div v-else class="fi-tree-list">
      <vue-ads-table-tree
        :columns="columns"
        :rows="fiTreeList"
        :classes="classes"
        :items-per-page="fiTreeList.length"
      >
        <template slot="top">{{ '' }}</template>

        <template slot="state" slot-scope="props">
          {{ props.row.state === '0 ' ? '' : props.row.state }}
        </template>

        <template slot="assets" slot-scope="props">
          {{ parseFloat(props.row.assets) | formatValue('$#,###') }}
        </template>

        <template slot="parent_id" slot-scope="props">
          {{ props.row.parent_id === 0 ? '' : props.row.parent_id }}
        </template>

        <template slot="no-rows">No Records Found.</template>
        <template slot="sort-icon">{{ '' }} </template>

        <template slot="bottom">
          {{ '' }}
        </template>
      </vue-ads-table-tree>
    </div>
  </b-modal>
</template>
<script>
import VueAdsTableTree from 'vue-ads-table-tree'
import Excel from 'exceljs'
import downloadsUtil from '@/modules/core/api/downloads'
import Vue from 'vue'

export default {
  name: 'SubStructureModal',
  components: {
    VueAdsTableTree
  },
  props: {
    selectedInstitution: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      filterValue: '',
      currentPage: 1,
      perPage: 2,
      loading: false,
      columns: [
        {
          property: 'rssd_id',
          title: 'RSSD ID',
          direction: null,
          filterable: false,
          groupCollapsable: true
        },
        {
          property: 'company',
          title: 'Company',
          direction: null,
          filterable: false
        },

        {
          property: 'city',
          title: 'City',
          direction: null,
          filterable: false
        },
        {
          property: 'state',
          title: 'State',
          direction: null,
          filterable: false
        },
        {
          property: 'charter_type',
          title: 'Charter',
          direction: null,
          filterable: false
        },
        {
          property: 'parent_id',
          title: 'Parent RSSD ID',
          direction: null,
          filterable: false
        },
        {
          property: 'assets',
          title: 'Total Assets(000s)',
          direction: null,
          filterable: false
        }
      ],
      fiTreeList: [],
      fiFlatList: [],
      classes: {
        table: {
          table: true,
          'table-striped': true,
          'vue-ads-w-full': false,
          'vue-ads-font-sans': false
        },
        group: {
          'font-bold': true,
          'border-b': true,
          italic: true
        },
        '0/all': {
          'py-3': true,
          'px-3': true
        },
        '1_/0': {
          leftAlign: true
        },
        'all/6': {
          rightAlign: true
        }
      }
    }
  },

  created() {
    this.getFiTreeList()
  },
  methods: {
    getFiTreeList() {
      this.loading = true
      this.$http
        .get('api/banks/' + this.selectedInstitution.id + '/sub_structure', {
          handleErrors: true
        })
        .then(res => {
          this.fiTreeList = res.data
          this.flatten(this.fiTreeList)
          this.loading = false
        })
    },

    flatten(list) {
      list.forEach(element => {
        if (element._children) {
          this.fiFlatList.push(element)
          this.flatten(element._children)
        } else {
          this.fiFlatList.push(element)
        }
      })
    },
    downloadReport() {
      const workbook = new Excel.Workbook()
      const sheet = workbook.addWorksheet('Holding Company Structure')
      sheet.addRow([
        'Copyright ' +
          new Date().getFullYear() +
          " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
      ])
      sheet.addRow()
      sheet.columns = [
        { key: 'rssd_id', width: 20 },
        { key: 'company', width: 60 },
        { key: 'city', width: 30 },
        { key: 'state', width: 30 },
        { key: 'charter_type', width: 30 },
        { key: 'parent_id', width: 30 },
        { key: 'assets', width: 30 }
      ]
      sheet.getRow(3).values = [
        'RSSD ID',
        'Company',
        'City',
        'State',
        'Charter',
        'Parent RSSD ID',
        'Total Assets(000s)'
      ]

      sheet.getRow(3).eachCell(cell => {
        cell.style = {
          font: {
            name: 'Calibri',
            bold: true,
            size: 14
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE4E5E6' }
          },
          border: {
            top: { style: 'double', color: { argb: 'FF525252' } },
            left: { style: 'double', color: { argb: 'FF525252' } },
            bottom: { style: 'double', color: { argb: 'FF525252' } },
            right: { style: 'double', color: { argb: 'FF525252' } }
          }
        }
      })

      this.fiFlatList.forEach(item => {
        let sheetRow = sheet.addRow({
          rssd_id: item.rssd_id,
          company: item.company,
          city: item.city,
          state: item.state === '0 ' ? '' : item.state,
          charter_type: item.charter_type,
          parent_id: item.parent_id == 0 ? '' : item.parent_id,
          assets: Vue.filter('formatValue')(item.assets, '$#,###')
        })

        sheetRow.eachCell((cell, cellIndex) => {
          if (cellIndex == 7) {
            cell.alignment = { horizontal: 'right' }
          }
        })
      })

      downloadsUtil.downloadAsExcel(workbook, `Holding Company Structure`)
    }
  }
}
</script>
<style lang="scss">
.fi-tree-list {
  thead tr th {
    border-bottom: 1px solid black;
    span {
      font-size: 0.85rem;
    }
  }
  .vue-ads-pr-2 {
    padding-right: 0.5rem !important;
  }
  .vue-ads-ml-2 {
    margin-left: 0.5rem !important;
    display: none;
  }
  .vue-ads-cursor-pointer {
    cursor: pointer;
  }
  .leftAlign {
    text-align: left;
  }
  .rightAlign {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .modal .modal-2xl {
    max-width: 85% !important;
    width: 85% !important;
  }
}
</style>
<style scoped lang="scss"></style>
